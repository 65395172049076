import { PropsWithChildren, ReactElement } from "react";
import { useAsync } from "react-use";

import { BrowserAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { getAccessToken, getActiveAccount, login } from "@bps/msal";

const useMsalProgressStatus = () => {
  const { inProgress } = useMsal();

  switch (inProgress) {
    case InteractionStatus.Startup:
    case InteractionStatus.HandleRedirect:
      return "Initialising...";

    case InteractionStatus.AcquireToken:
    case InteractionStatus.SsoSilent:
      return "Checking your login details...";

    case InteractionStatus.Login:
      return "Logging in...";

    case InteractionStatus.Logout:
      return "Logging out...";

    case InteractionStatus.None:
    default:
      return null;
  }
};

export interface AuthenticatedProps {
  renderSpinner: (message?: string) => ReactElement;
  renderError?: (
    message: string,
    errorCode: string | undefined
  ) => ReactElement;
}

export const Authenticated = ({
  renderSpinner,
  renderError,
  children
}: PropsWithChildren<AuthenticatedProps>) => {
  const status = useMsalProgressStatus();

  const {
    loading,
    error,
    value: account
  } = useAsync(async () => {
    const account = await getActiveAccount();

    // msal is still doing its dance
    if (!!status) return undefined;
    if (account) return account;

    const accessToken = await getAccessToken();
    if (!accessToken) await login();
    return undefined;
  }, [status]);

  const authError = error as BrowserAuthError;

  if ((!account || loading) && status) return renderSpinner(status);

  if (!loading && error && renderError)
    return renderError(
      authError?.errorMessage ?? authError.message,
      authError?.errorCode
    );
  return <>{children}</>;
};
