import { Authenticated } from "components/Authenticated";
import { Spinner } from "components/Spinner";
import Notifications from "layout/Notifications";
import { ComponentType, ExoticComponent, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { useAppInsightsPageViewTracker } from "./core/analytics/app-insights/app-insights.hooks";
import { usePageTitle } from "./core/hooks/usePageTitle";
import { routes } from "./core/routes/routes";

interface BhwModuleRoute {
  path: string;
  allowAnonymous?: boolean;
  resolveComponent: () => Promise<{ default: ComponentType }>;
}

const Layout = lazy(() => import("layout/Layout"));

const modules: BhwModuleRoute[] = [
  {
    path: "/profile/incomplete",
    resolveComponent: () => import("modules/booking")
  },
  {
    path: routes.bha.path,
    resolveComponent: () => import("modules/bha")
  },
  {
    path: routes.setup.path,
    allowAnonymous: true,
    resolveComponent: () => import("modules/setup")
  },
  {
    path: routes.booking.path,
    allowAnonymous: true,
    resolveComponent: () => import("modules/bhb")
  }
];

interface BhwModuleProps {
  allowAnonymous?: boolean;
  component: ExoticComponent;
}

const AppModule = ({
  allowAnonymous,
  component: Component
}: BhwModuleProps) => {
  if (allowAnonymous) {
    return <Component />;
  }

  return (
    <Authenticated
      renderSpinner={spinnerMessage => (
        <Spinner variant="fullscreen">{spinnerMessage}</Spinner>
      )}
    >
      <Component />
    </Authenticated>
  );
};

export const AppRoutes = () => {
  usePageTitle();
  useAppInsightsPageViewTracker();
  return (
    <>
      <Suspense
        fallback={
          <Spinner variant="fullscreen">Loading, please wait...</Spinner>
        }
      >
        <Routes>
          {modules.map(({ path, allowAnonymous, resolveComponent }) => (
            <Route
              key={path}
              path={path}
              element={
                <AppModule
                  allowAnonymous={allowAnonymous}
                  component={lazy(resolveComponent)}
                />
              }
            />
          ))}

          <Route path="*" element={<Layout />} />
        </Routes>
      </Suspense>

      <Notifications />
    </>
  );
};
